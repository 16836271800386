import { hasValue } from './methods'

// This is a label which appears as a placeholder but floats up
// to be a label above the text when the input has content in it.
// It expects the label has the `for` property pointing to the name of
// the input it applies to and for that input to exist somewhere in it's
// parent. It applies the `has-content` class to itself and it's input.

// This code is imperative rather than reactive, but this allows the behavior
// to be very simple and decoupled from the type of input in question,
// and no special components are required to enable the functionality.
function updateElement(input) {
  if (input.classList.contains('peer')) {
    return
  }

  const name = input.getAttribute('name')
  if (!name) return
  const type = input.getAttribute('type')
  if (['checkbox', 'radio', 'hidden'].indexOf(type) >= 0) return

  const labels = Array.from(input.parentElement.querySelectorAll('label'))
  const label = labels.find(
    (l) => [name, input.id].indexOf(l.getAttribute('for')) >= 0,
  )
  if (!label) return

  if (hasValue(input.value)) {
    label.classList.add('has-content')
    input.classList.add('has-content')
  } else {
    label.classList.remove('has-content')
    input.classList.remove('has-content')
  }
}
window.addEventListener('load', () => {
  ;['keyup', 'change', 'input'].forEach((name) => {
    document.body.addEventListener(name, (event) => {
      const target = event.target
      if (!target) return
      if (target.tagName !== 'INPUT' && target.tagName !== 'TEXTAREA') return

      updateElement(event.target)
    })
  })
  // also apply to any elements that initially have a value
  document.querySelectorAll('input,textarea').forEach(updateElement)
})
