import Tracker from '@/util/tracker.js'

window.addEventListener('load', (ev) => {
  Tracker.track('page loaded', {
    url: window.location.toString(),
    'detected country': document.querySelector('meta[name="detected_country"]')
      .content,
    'detected language': document.querySelector(
      'meta[name="detected_language"]',
    ).content,
    'url language': window?.language,
    'url country': window?.region,
    currency: '',
    clean_url: `${window.location.origin.toString()}${window.location.pathname.toString()}`,
  })
})

window.addEventListener('error', (ev) => {
  Tracker.track('page errored', {
    message: ev.message,
    filename: ev.filename,
    lineno: ev.lineno,
    colno: ev.colno,
    url: window.location.toString(),
  })
})
